@layer reset, thirdparty, custom, utilities, components, designsystem;

@import "./fonts/Inter/inter.css";
@import "./custom.css";
@import "../../node_modules/@tine/designsystem-ui-react/dist/style.css" layer(designsystem);
@import "../../node_modules/@tine/designsystem-tokens/dist/css/isdalen/variables.css" layer(designsystem);

@layer reset {
   @tailwind base;
}

@tailwind components;
@tailwind utilities;

@layer reset, thirdparty, custom, utilities, components, designsystem;

@layer custom {
   .confirmation-banner {
      background-image: url("../img/themes/isdalen/order_confirmation.jpg?width=1792");
      background-image: image-set(
         url("../img/themes/isdalen/order_confirmation.jpg?width=1792&as=webp") 1.01x,
         url("../img/themes/isdalen/order_confirmation.jpg?width=1792") 1.01x
      );
   }
}

@layer utilities {
   :root {
      --default-font-family: "Inter", "Maison Neue", Helvetica, Arial, sans-serif;
      --color-ink-favorite: var(--color-red-700);

      --color-promotion: var(--color-red-700);
      --color-body-bg: #fafafa;
      --color-christmas: var(--color-red-700);
      --color-info-box-bg: var(--color-cyan-50);
      --color-ink-muted: var(--color-base-500);

      --color-header-footer-bg: var(--color-surface-default-inverted);
      --color-header-footer-text: var(--color-ink-brand-inverted);

      --notification-marker-surface: var(--color-red-700);
      --notification-marker-ink: var(--color-ink-brand-inverted);
   }
}

@font-face {
   font-family: "suomi";
   src: url("./fonts/Suomi-Hand.otf");
   font-display: swap;
}
